import { useEffect, useState } from 'react';
import Tab from './Components/Tab';
import './styles/style.scss';
import Button from '../../components/Form/Button';
import Icon from '../../components/Icon';
import { useTranslate } from '../../i18translate/Hooks';
import { I18 } from '../../languages/I18';
import LoginModal from './Components/LoginModal';
import { useGetArticlesQuery } from './services/APICommonService';
import { useLazyGetAllProjectsQuery } from './services/APIMainService';
import RecentProjects from './Components/RecentProjects';
import { login, useSession } from '../../utils/Auth';
import BrandPlate from '../../components/BrandPlate';
import Lottie from 'react-lottie';
import Banner from '../../assets/images/Banner.json';
import useDeviceDetect from '../../hooks/useDeviceDetect';
import { appInsights } from '../../services/AppInsight';
import ProjectTypeSelectionModal from './Components/ProjectTypeSelectionModal';

import { setProjectTypeSelected } from './store/actions/homeActions';
import { useDispatch } from 'react-redux';

const Home = () => {
  const { t } = useTranslate();
  const dispatch = useDispatch();
  const { isDesktop } = useDeviceDetect();
  const { session, loading } = useSession();
  const [modalOpen, setModalOpen] = useState(false);
  const [isButtonDisabled, setIsButtonDisabled] = useState(false);
  const { data: articlesData } = useGetArticlesQuery('');
  const [viewProjectTypeSelectionModal, setViewProjectTypeSelectionModal] =
    useState(false);

  const [projects, setProjects] = useState<{
    isProjectDataLoading: boolean;
    projectData: {} | undefined | any;
    isProjectDataFetching: string | undefined;
  }>({
    isProjectDataLoading: false,
    projectData: {},
    isProjectDataFetching: '',
  });

  const defaultOptions = {
    loop: true,
    autoplay: true,
    animationData: Banner,
  };
  const [getAllProjectsData] = useLazyGetAllProjectsQuery();

  const onClose = () => {
    setModalOpen(false);
  };

  const onProjectTypeSelectionModalClose = () => {
    setViewProjectTypeSelectionModal(false);
  };

  useEffect(() => {
    if (appInsights) {
      appInsights.trackPageView({
        name: 'AbbGoSelect homepage',
        pageType: 'Main page',
      });
    } else {
    }
  }, []);

  useEffect(() => {
    if (session?.user.access_token && !loading) {
      getAllProjects();
    }
  }, [loading]);

  const onDelete = () => {
    getAllProjects();
  };

  const getAllProjects = () => {
    setProjects({
      ...projects,
      isProjectDataLoading: true,
    });
    getAllProjectsData(session?.user.access_token)
      .then((response) => {
        if (response.status === 'fulfilled') {
          if (response.data) {
            setProjects({
              ...projects,
              isProjectDataLoading: false,
              projectData: response.data,
            });

            if (response.data.projects.length >= 50) {
              setIsButtonDisabled(true);
            } else if (response.data.projects.length < 50) {
              setIsButtonDisabled(false);
            }
          }
        } else {
          setProjects({
            ...projects,
            isProjectDataLoading: false,
            projectData: {},
          });
        }
      })
      .catch((e) => {
        setProjects({
          ...projects,
          isProjectDataLoading: false,
          projectData: {},
        });
      });
  };

  const handleProjectOptionClick = (option: number) => {
    setModalOpen(true);
    dispatch(setProjectTypeSelected(option.toString()));
    setViewProjectTypeSelectionModal(false);
  };

  const handleOnNewProjectClick = () => {
    if (session?.user) {
      setViewProjectTypeSelectionModal(true);
    } else {
      setModalOpen(true);
    }
  };

  return (
    <div className="layout-container" data-rf="dashboard-content">
      <div
        className={`homepage-header-container page-header-container ${
          session?.user ? '' : 'homepage-header-container-unauthorize'
        }`}
      >
        <div>
          <div className="homepage-common-info">
            <section>
              <BrandPlate
                type="brand-color"
                size="default"
                hideLogo={true}
                className="homepage-info-section"
                productname={t(I18.app_productname)}
              />
              <p className="homepage-dashboard-text">
                {t(I18.home_dashboard_title)}
              </p>
            </section>

            {!session?.user && (
              <p className="homepage-dashboard-description">
                {t(I18.homepage_dashboard_description)}
              </p>
            )}
          </div>
          <div className="homepage-info-section">
            {!session?.user ? (
              <>
                <p>{t(I18.homepage_info_section)}</p>
                <div>
                  <span>
                    <Button
                      type="primary-red"
                      size="xlarge"
                      onClick={() => setModalOpen(true)}
                      data-rf="new-project-button"
                      disabled={isButtonDisabled}
                      data-testid="newproject-button"
                    >
                      <Icon
                        slot="icon"
                        size="small"
                        name="plus"
                        theme="light"
                      />
                      {t(I18.home_dashboard_new_project_button)}
                    </Button>
                  </span>
                  <span className="login-button">
                    <Button
                      type="tertiary"
                      size="xlarge"
                      disabled={false}
                      onBtnClick={login}
                      data-testid="login-button"
                    >
                      <Icon
                        slot="icon"
                        size="small"
                        name="user"
                        data-rf="login-button"
                      />
                      {t(I18.app_header_login)}
                    </Button>
                  </span>
                </div>
              </>
            ) : (
              <RecentProjects
                handleOnNewProjectClick={handleOnNewProjectClick}
                isLoading={projects.isProjectDataLoading}
                projectData={projects.projectData}
                onSuccessOfDeleteProject={onDelete}
                isButtonDisabled={isButtonDisabled}
              />
            )}
          </div>
        </div>
        <div hidden={!isDesktop}>
          {(!session?.user ||
            (Object.keys(projects.projectData).length === 0 &&
              !projects.isProjectDataLoading)) && (
            <Lottie
              options={defaultOptions}
              height={400}
              width={350}
              isStopped={false}
              isPaused={false}
            />
          )}
        </div>
      </div>

      <div className="homepage-things-to-know-container">
        <h4>{t(I18.home_things_to_know_label)}</h4>
        <Tab
          articlesData={
            articlesData &&
            [...articlesData].sort(
              (a, b) =>
                new Date(b.updateDate).getTime() -
                new Date(a.updateDate).getTime(),
            )
          }
        />
      </div>

      <LoginModal
        modalOpen={modalOpen}
        onClose={onClose}
        projectData={projects.projectData?.projects}
      />
      <ProjectTypeSelectionModal
        handleProjectOptionClick={handleProjectOptionClick}
        modalOpen={viewProjectTypeSelectionModal}
        onClose={onProjectTypeSelectionModalClose}
      />
    </div>
  );
};

export default Home;
