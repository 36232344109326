export interface NotificationOptions {
  type?: 'default' | 'discreet';
  isAutoClose?: boolean;
  autoCloseDuration?: number;
  position?: 'top' | 'bottom' | 'middle';
  iconName?: string;
}
class NotificationManager {
  private notificationElement?: HTMLElement = undefined;
  private notificationElementName: string = 'root-component-notification';
  private setNotificationElement() {
    if (this.notificationElement) return;
    let element = document.getElementById(this.notificationElementName);
    if (!element) {
      let newElement = document.createElement('commonux-notification');
      newElement.setAttribute('id', this.notificationElementName);
      document.body.appendChild(newElement);
      element = newElement;
    }
    this.notificationElement = element;
    this.notificationElement.setAttribute('position', 'top');
  }

  public success(message: string, options?: NotificationOptions) {
    this.setNotificationElement();
    this.notificationElement?.setAttribute('color', 'success-discreet');
    this.notificationElement?.setAttribute('icon', 'check-mark-circle-1-24');
    this.notificationElement?.setAttribute('data-rf', 'success-notification');
    this.setOptions(message, options);
    this.toast(options);
  }

  public info(message: string, options?: NotificationOptions) {
    this.setNotificationElement();
    this.notificationElement?.setAttribute('color', 'info-discreet');
    this.notificationElement?.setAttribute('icon', 'information-circle-1-24');
    this.notificationElement?.setAttribute('data-rf', 'info-notification');
    this.setOptions(message, options);
    this.toast(options);
  }
  public warning(message: string, options?: NotificationOptions) {
    this.setNotificationElement();
    this.notificationElement?.setAttribute('color', 'warning-discreet');
    this.notificationElement?.setAttribute('icon', 'warning-triangle-24');
    this.notificationElement?.setAttribute('data-rf', 'warning-notification');
    this.setOptions(message, options);
    this.toast(options);
  }
  public error(message: string, options?: NotificationOptions) {
    this.setNotificationElement();
    this.notificationElement?.setAttribute('color', 'error-discreet');
    this.notificationElement?.setAttribute('icon', 'error-circle-1-24');
    this.notificationElement?.setAttribute('data-rf', 'error-notification');
    this.setOptions(message, options);
    this.toast(options);
  }
  private setOptions(message: string, options?: NotificationOptions) {
    this.notificationElement?.setAttribute('message', message);
    if (!options) return;
    options.position &&
      this.notificationElement?.setAttribute('position', options.position);
    options.autoCloseDuration &&
      this.notificationElement?.setAttribute(
        'duration',
        options.autoCloseDuration.toString(),
      );
    options.iconName &&
      this.notificationElement?.setAttribute('icon', options.iconName);
    if (options.type) {
      let defaultColor = this.notificationElement?.getAttribute('color') ?? '';
      this.notificationElement?.setAttribute(
        'color',
        options.type === 'default' ? defaultColor : `${defaultColor}`,
      );
    }
  }

  private toast(options?: NotificationOptions) {
    if (options) {
      !options.isAutoClose && (this.notificationElement as any).presentToast();
      return;
    } //by default autoclose will happen if not overriden by options
    (this.notificationElement as any)?.presentToastautoclose?.();
  }
}
const notificationManager = new NotificationManager();
export default notificationManager;
