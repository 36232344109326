import { CommonuxTextArea } from '@abb-motion-ux/commonux-web-components-react';
import { addAttachmentButton } from './GeneralFeedback';

const SuggestionFeedback = () => {
  return (
    <div>
      <div className="feedback-modal-text" slot="content">
        <div className="feedback-modal-question-section">
          <div className="feedback-question-container">
            <div className="">
              <CommonuxTextArea
                labelText="Do you have any suggestions for new features or improvements?"
                placeholder="Leave us your comments"
                minHeight="160px"
              />
            </div>
          </div>
          {addAttachmentButton()}
        </div>
      </div>
    </div>
  );
};

export default SuggestionFeedback;
