import './../styles/layout.scss';
import { Rating } from 'react-simple-star-rating';
import { CommonuxTextArea } from '@abb-motion-ux/commonux-web-components-react';
import Button from '../../components/Form/Button';
import Icon from '../../components/Icon';

export const addAttachmentButton = () => {
  return (
    <div>
      <Button>
        <Icon name="image" size="small" slot="icon" /> Add attachment
      </Button>
      <div className="attachment-text-container">
        <div className="attachment-support-text">
          <div>Supported file : Jpeg, png, Pdf</div>
          <div>Maximum upload file size : 10MB</div>
        </div>
        <div>
          <b>Note:</b> We might contact you in the future to gather further
          details or resolve the issue.
        </div>
      </div>
    </div>
  );
};

const GeneralFeedback = () => {
  const ratingChanged = (newRating: number) => {
    console.log(newRating);
  };

  return (
    <div>
      <div className="feedback-modal-text" slot="content">
        <div className="feedback-modal-question-section">
          <div className="feedback-question-container">
            <div className="feedback-question-text">
              How satisfied are you with the tool overall?
            </div>

            <div className="feedback-question-content">
              <Rating
                onClick={ratingChanged}
                size={55}
                transition
                fillColor="#1f1f1f"
                className="feedback-stars"
              />
              <div className="feedback-question-subtext">
                <div>Very Dissatisfied</div>
                <div>Very Satisfied</div>
              </div>
            </div>
          </div>

          <div className="feedback-question-container">
            <div className="feedback-question-text">
              How easy is it to navigate and use the tool?
            </div>

            <div className="feedback-question-content">
              <Rating
                onClick={ratingChanged}
                size={55}
                transition
                fillColor="#1f1f1f"
                className="feedback-stars"
              />
              <div className="feedback-question-subtext">
                <div>Very Difficult</div>
                <div>Very Easy</div>
              </div>
            </div>
          </div>

          <div className="feedback-question-container">
            <div className="feedback-question-text">
              How well does the tool meet your requirements?
            </div>

            <div className="feedback-question-content">
              <Rating
                onClick={ratingChanged}
                size={55}
                transition
                fillColor="#1f1f1f"
                className="feedback-stars"
              />
              <div className="feedback-question-subtext">
                <div>Does not meet requirements</div>
                <div>Fully meets requirements</div>
              </div>
            </div>
          </div>

          <div className="feedback-question-container">
            <div className="">
              <CommonuxTextArea
                labelText="Additional comments"
                placeholder="Leave us your comments"
              />
            </div>
          </div>
          {addAttachmentButton()}
        </div>
      </div>
    </div>
  );
};

export default GeneralFeedback;
