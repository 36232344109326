import './../styles/layout.scss';
import { CommonuxTextArea } from '@abb-motion-ux/commonux-web-components-react';
import { addAttachmentButton } from './GeneralFeedback';

const ReportIssueFeedback = () => {
  return (
    <div>
      <div className="feedback-modal-text" slot="content">
        <div className="feedback-modal-question-section">
          <div className="feedback-question-container">
            <div className="">
              <CommonuxTextArea
                labelText="What challenges have you faced while using the tool?"
                placeholder="Leave us your comments"
              />
            </div>
          </div>
          <div className="feedback-question-container">
            <div className="">
              <CommonuxTextArea
                labelText="Describe the problem in detail"
                placeholder="Leave us your comments"
              />
            </div>
          </div>
          {addAttachmentButton()}
        </div>
      </div>
    </div>
  );
};

export default ReportIssueFeedback;
