import Button from '../../components/Form/Button';
import Icon from '../../components/Icon';
import './../styles/layout.scss';
import Modal from '../../components/Modal';
import { useEffect, useState } from 'react';
import GeneralFeedback from './GeneralFeedback';
import ReportIssueFeedback from './ReportIssueFeedback';
import SuggestionFeedback from './SuggestionFeedback';
import GenericModal from './GiveFeedback';

const Feedback = () => {
  const [open, setOpen] = useState(false);

  const [feedbackType, setFeedbackType] = useState<number | null>(null);

  useEffect(() => {
    if (!open) {
      setFeedbackType(null);
    }
  }, [open]);

  const SubmittedFeedback = () => {
    return (
      <div className="feedback-submitted-container">
        <div className="feedback-modal-submit-icon">
          <Icon name="check-mark-circle-2" size="large" />
        </div>
        <div>
          <div className="submit-success-title">Submitted successfully</div>
          <div className="submit-success-subtext">
            Thanks for contacting us! We’ll get back to you soon.
          </div>
        </div>
      </div>
    );
  };

  const handleFeedbackType = (type: number | null) => {
    switch (type) {
      case null: {
        return <GenericModal setFeedbackType={setFeedbackType} />;
      }
      case 1: {
        return <GeneralFeedback />;
      }
      case 2: {
        return <ReportIssueFeedback />;
      }
      case 3: {
        return <SuggestionFeedback />;
      }
      case 4: {
        return <SubmittedFeedback />;
      }
    }
  };

  const modalTitle = (type: number | null) => {
    switch (type) {
      case null: {
        return 'Give feedback';
      }
      case 1: {
        return 'General feedback';
      }
      case 2: {
        return 'Report an issue';
      }
      case 3: {
        return 'Feature suggestion';
      }
      case 4: {
        return 'Feedback submitted';
      }
    }
  };

  const handleSubmit = () => {
    setFeedbackType(4);
  };

  return (
    <div style={{ height: 0 }}>
      <div className="feedback-button">
        <Button
          type="secondary"
          size="small"
          onClick={() => setOpen(true)}
          data-testid="give-feedback-button"
        >
          <Icon slot="icon" size="small" name="open-in-new-window" />
          Give Feedback
        </Button>
      </div>
      <Modal
        open={open}
        onModalClose={() => setOpen(false)}
        modalTitle={modalTitle(feedbackType)}
        showBackButton={feedbackType && feedbackType !== 4 ? true : false}
        showCloseButton={true}
        onBack={() => setFeedbackType(null)}
        type="default"
        backdropClick={false}
        data-testid="give-feedback-modal"
      >
        <div slot="content">{handleFeedbackType(feedbackType)}</div>
        <div className="report-graph-modal-footer" slot="footer">
          {feedbackType && feedbackType !== 4 && (
            <>
              <div className="info-cancel-button-container">
                <Button
                  type="discreet-black"
                  size="small"
                  onClick={() => setFeedbackType(null)}
                  className="info-cancel-button"
                  data-rf="confirmation-modal-cancel-button"
                >
                  Back
                </Button>
              </div>
              <div className="open-button-container">
                <Button
                  type="primary-black"
                  size="small"
                  onClick={handleSubmit}
                  className="info-button"
                  data-rf="confirmation-modal-open-button"
                >
                  Submit
                </Button>
              </div>
            </>
          )}
          {feedbackType && feedbackType === 4 && (
            <>
              {/* <div className="info-cancel-button-container">
                <Button
                  type="discreet-black"
                  size="small"
                  onClick={() => setFeedbackType(null)}
                  className="info-cancel-button"
                  data-rf="confirmation-modal-cancel-button"
                >
                  Go to project
                </Button>
              </div> */}
              <div className="open-button-container">
                <Button
                  type="primary-black"
                  size="small"
                  onClick={() => setOpen(false)}
                  className="info-button"
                  data-rf="confirmation-modal-open-button"
                >
                  Okay
                </Button>
              </div>
            </>
          )}
        </div>
      </Modal>
    </div>
  );
};

export default Feedback;
