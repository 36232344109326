import Modal from '../../../components/Modal';
import {
  ProjectType,
  ProjectTypeSelectionProps,
} from '../models/homepageModel';
import driveAndMotorIcon from '../../../../src/assets/images/pds.svg';
import plcAndHMIIcon from '../../../assets/images/AC500.svg';

const ProjectTypeSelectionModal = ({
  modalOpen,
  onClose,
  handleProjectOptionClick,
}: ProjectTypeSelectionProps) => {
  const handlePLCHMIOption = () => {
    handleProjectOptionClick(ProjectType.PLCHMI);
  };

  return (
    <>
      <Modal
        open={modalOpen}
        onModalClose={onClose}
        modalTitle={'Select project type'}
        showCloseButton={true}
        type="discreet"
        showHederSeparator="true"
        backdropClick={false}
        className="project-type-selection-modal"
        data-testid="project-type-selection-modal"
      >
        <div
          slot="content"
          id="project-type-selection-modal-content"
          data-rf="project-type-selection-modal-content"
          className="project-type-option-container"
        >
          <div
            role="button"
            onKeyDown={(e) => {
              if (e.key === 'Enter' || e.key === ' ') {
                handleProjectOptionClick(ProjectType.DriveMotor);
              }
            }}
            className="drive-motor-option-container"
            onClick={() => handleProjectOptionClick(ProjectType.DriveMotor)}
          >
            <img src={driveAndMotorIcon} alt="drive and motor icon" />
            <div className="option-title">Drive & Motor Project</div>
          </div>
          <div
            role="button"
            className="plc-hmi-option-container"
            onClick={handlePLCHMIOption}
            onKeyDown={(e) => {
              if (e.key === 'Enter' || e.key === ' ') {
                handlePLCHMIOption();
              }
            }}
          >
            <img src={plcAndHMIIcon} alt="plchmiicon" />
            <div className="option-title">PLC & HMI Project</div>
          </div>
        </div>
      </Modal>
    </>
  );
};

export default ProjectTypeSelectionModal;
