import React, { useEffect, useLayoutEffect, useState } from 'react';
import Modal from '../../../components/Modal';
import Select from '../../../components/Form/Select';
import SelectOption from '../../../components/Form/SelectOption';
import SelectContainer from '../../../components/Form/SelectContainer';
import Icon from '../../../components/Icon';
import Input from '../../../components/Form/Input';
import Button from '../../../components/Form/Button';
import {
  NewProjectInfo,
  GeneralProjectDetails,
  CurrentProjectDetails,
  ProjectType,
} from '../models/homepageModel';
import useInput from '../../../hooks/useInput';
import { I18 } from '../../../languages/I18';
import { useTranslate } from '../../../i18translate/Hooks';
import { useGetCountriesQuery } from '../services/APICommonService';
import { useCreateProjectMutation } from '../services/APIMainService';
import { GetCurrentCountry } from '../../../utils/GeneralUtils';
import notificationManager from './../../../utils/NotificationManager';
import { useSession } from '../../../utils/Auth';
import CommonLoader from '../../../components/Loader';
import TextArea from '../../../components/Form/TextArea';
import { useSelector } from 'react-redux';
import { RootState } from '../../../store/rootReducer';
import Cookies from 'js-cookie';

interface SelectionModalProps {
  modalOpen?: boolean;
  onClose?: () => void;
  onCreateProject: (newProjectInfo: GeneralProjectDetails) => void;
  projectData: CurrentProjectDetails[] | undefined;
}

const SelectionModal: React.FunctionComponent<SelectionModalProps> = ({
  modalOpen,
  onClose,
  onCreateProject,
  projectData,
}) => {
  const { t } = useTranslate();
  const { session } = useSession();
  const [standard, setStandard] = React.useState('');
  const [unitSystem, setUnitSystem] = React.useState('');
  const { data: countriesData, isLoading: isCountriesLoading } =
    useGetCountriesQuery('');
  const [isLoading, setIsLoading] = React.useState(false);
  const [createProject] = useCreateProjectMutation();
  const currentCountryId = GetCurrentCountry().id;
  const [countryName, setCountryName] = React.useState('');
  const [destinationCountry, setDestinationCountry] = React.useState('');
  const [defaultProjectName, setDefaultProjectName] = useState('New Project');
  const [description, setDescription] = useState('');
  const projectTypeSelected = useSelector(
    (state: RootState) => state.home.projectTypeSelected,
  );

  const {
    value: projectName,
    isValid: isProjectNameValid,
    valueChangedHandler: onProjectNameChange,
  } = useInput((value: string) => value.trim() !== '', defaultProjectName);

  let newProjectInfo: NewProjectInfo;

  useLayoutEffect(() => {
    setCountryName(currentCountryId ?? '');
    setDestinationCountry(currentCountryId ?? '');
    setStandard('IEC');
    setUnitSystem('SI');
  }, [currentCountryId, isCountriesLoading]);

  useEffect(() => {
    if (projectData && projectData?.length > 0) {
      let listNames: any[] = [];
      Object.values(projectData).forEach((list: any) =>
        listNames.push(list.name),
      );
      let result = findFirstMissingProject(listNames);
      result
        ? setDefaultProjectName(result)
        : setDefaultProjectName('New Project');
    } else {
      setDefaultProjectName('New Project');
    }
  }, [projectData]);

  const findFirstMissingProject = (namearray: string[]) => {
    if (localStorage.getItem('projectname') === 'New Project') {
      if (namearray.length === 1 && namearray[0].trim() !== 'New Project') {
        return 'New Project';
      }
    }

    namearray.sort((a, b) => a.localeCompare(b, undefined, { numeric: true }));
    let expectedNumber = 1;
    let missingProject;

    for (const projectName of namearray) {
      const projectNumberMatch = projectName.match(/\(\d+\)/); // Extract digits in parentheses

      if (!projectNumberMatch) {
        continue;
      }
      const projectNumber = parseInt(projectNumberMatch[0].slice(1, -1)); // Extract digits, remove parentheses

      if (projectNumber !== expectedNumber) {
        missingProject = `New Project (${expectedNumber})`;
        break;
      }

      expectedNumber++;
    }

    if (!missingProject && expectedNumber <= namearray.length) {
      missingProject = `New Project (${expectedNumber})`;
    }

    return missingProject;
  };

  const handleSelectionDropdownChange = (
    content: string,
    data: CustomEvent<any>,
  ) => {
    switch (content) {
      case 'country':
        setCountryName(data.detail.value);
        break;
      case 'destination':
        setDestinationCountry(data.detail.value);
        break;
      case 'standard':
        setStandard(data.detail.value);
        break;
      case 'unit':
        setUnitSystem(data.detail.value);
        break;
      case 'description':
        setDescription(data.detail.value);
        break;
    }
  };

  let isFormValid =
    isProjectNameValid &&
    countryName &&
    destinationCountry &&
    standard &&
    unitSystem;

  const onCreateProjectClick = () => {
    setIsLoading(true);
    if (isFormValid) {
      newProjectInfo = {
        projectName,
        countryName,
        destinationCountry,
        standard,
        unitSystem,
        description,
        projectType: projectTypeSelected,
      };

      createProject({
        userToken: session?.user.access_token,
        projectData: newProjectInfo,
      })
        .then((response) => {
          if (response.data) {
            onCreateProject(response.data);
            if (projectTypeSelected === ProjectType.PLCHMI.toString()) {
              if (process.env.REACT_APP_PLC_CKNAME) {
                const date = new Date();
                Cookies.set(
                  process.env.REACT_APP_PLC_CKNAME,
                  date.getTime().toString(),
                  {
                    expires: 1,
                    path: '/',
                    domain: '.goselect.motion.abb.com',
                  },
                );
              }
              const url = process.env.REACT_APP_PLC_REDIRECT
                ? `${process.env.REACT_APP_PLC_REDIRECT}?id=${response.data?.projectId}`
                : '';
              window.location.href = url;
            }
          } else {
            notificationManager.error(t('Unable to create project'));
          }
        })
        .finally(() => {
          setIsLoading(false);
        });
    }
  };
  return isCountriesLoading ? (
    <CommonLoader
      size="large"
      indicatorType={'circular'}
      progressType={'indeterminate'}
      progressValue={50}
      type={'primary-black'}
    />
  ) : (
    <Modal
      open={modalOpen}
      onModalClose={onClose}
      backdropClick={false}
      modalTitle={t(I18.modal_selection_title)}
      show-close-button={!isLoading ? 'true' : 'false'}
      type="discreet"
      showHederSeparator="true"
      className="selection-modal"
      showFooterSeparator={true}
    >
      <Icon size="small" name="certificate-document-1" slot="title-icon" />

      <div
        slot="content"
        className="create-project-modal-content"
        data-rf="create-project-modal-content"
      >
        <div className="form-group-header">
          {t(I18.modal_selection_create_project_title)}
        </div>
        <div id="inputs">
          <div className="sel-container">
            <Input
              label-text={t(I18.modal_selection_project_name_label)}
              size="medium"
              valid={true}
              required={false}
              placeholder={t(I18.modal_selection_project_name_placeholder)}
              className="input-style"
              value={projectName}
              inputLimit={50}
              dataRf="project-name-input"
              disabled={isLoading}
              onInputInput={(event) =>
                onProjectNameChange(event.target.value.toString())
              }
            />
          </div>

          <div className="sel-container">
            <div className="form-header">
              {t(I18.modal_selection_from_country_label)}
            </div>
            <SelectContainer size="small">
              <Select
                slot="main"
                placeholder={t(I18.modal_selection_country_label)}
                required={false}
                value={countryName}
                onSelectChange={(event) =>
                  handleSelectionDropdownChange('country', event)
                }
                data-rf="from-country-dropdown"
                disabled={isLoading}
              >
                <div
                  className="options-container"
                  slot="options-main"
                  data-rf="from-country-dropdown-options"
                >
                  {countriesData?.map((country) => {
                    return (
                      <SelectOption
                        key={country.code}
                        dataValue={country.code}
                        data-rf={country.code}
                        optionText={country.name}
                        selected={countryName === country.code}
                      />
                    );
                  })}
                </div>
              </Select>
            </SelectContainer>
            <div className="select-description">
              {projectTypeSelected !== '1'
                ? t(`modal_selection_country_description_for_plc`)
                : t(`modal_selection_country_description`)}
            </div>
          </div>

          <div className="sel-container">
            <div className="form-header">
              {t(I18.modal_selection_destination_label)}
            </div>
            <SelectContainer size="small">
              <Select
                slot="main"
                placeholder={t(I18.modal_selection_country_label)}
                required={false}
                size="small"
                value={destinationCountry}
                onSelectChange={(event) =>
                  handleSelectionDropdownChange('destination', event)
                }
                data-rf="destination-country-dropdown"
                disabled={isLoading}
              >
                <div
                  className="options-container"
                  slot="options-main"
                  data-rf="destination-country-dropdown-options"
                >
                  {countriesData?.map((country) => {
                    return (
                      <SelectOption
                        key={country.code}
                        data-value={country.code}
                        data-rf={country.code}
                        optionText={country.name}
                        selected={destinationCountry === country.code}
                      />
                    );
                  })}
                </div>
              </Select>
            </SelectContainer>
            <div className="select-description">
              {projectTypeSelected !== '1'
                ? t(`modal_selection_destination_country_description_for_plc`)
                : t(`modal_selection_destination_country_description`)}
            </div>
          </div>
          <div className="sel-container">
            <div className="form-header">
              {t(I18.modal_selection_standard_label)}
            </div>
            <SelectContainer size="small">
              <Select
                slot="main"
                placeholder={t(I18.modal_selection_standard_placeholder)}
                required={false}
                value={standard}
                onSelectChange={(event) =>
                  handleSelectionDropdownChange('standard', event)
                }
                data-rf="standard-dropdown"
                disabled={isLoading}
              >
                <div slot="options-main" data-rf="standard-dropdown-options">
                  <SelectOption
                    data-value="IEC"
                    data-rf="IEC"
                    optionText="IEC"
                    selected={standard === 'IEC'}
                  />
                  {/* uncomment when these standards are supported in future */}
                  {/* <SelectOption
                    data-value="UL"
                    data-rf="UL"
                    optionText="UL"
                    selected={standard === 'UL'}
                  />
                  <SelectOption
                    data-value="NEMA"
                    data-rf="NEMA"
                    optionText="NEMA"
                    selected={standard === 'NEMA'}
                  />
                  <SelectOption
                    data-value="CSA"
                    data-rf="CSA"
                    optionText="CSA"
                    selected={standard === 'CSA'}
                  />
                  <SelectOption
                    data-value="CUL"
                    data-rf="CUL"
                    optionText="CUL"
                    selected={standard === 'CUL'}
                  /> */}
                </div>
              </Select>
            </SelectContainer>
          </div>

          {/* Unit system dropdown */}
          <div className="sel-container">
            <div className="form-header">
              {t(I18.modal_selection_unit_system_label)}
            </div>
            <SelectContainer size="small">
              <Select
                slot="main"
                placeholder={t(I18.modal_selection_unit_system_placeholder)}
                required={false}
                value={unitSystem}
                onSelectChange={(event) =>
                  handleSelectionDropdownChange('unit', event)
                }
                data-rf="unti-system-dropdown"
                variant="floating"
                position="prefix"
                disabled={isLoading}
              >
                <div slot="options-main" data-rf="unti-system-dropdown-options">
                  {/* 
                  //uncomment when metric is supported in future
                   <SelectOption
                    data-value="Metric"
                    data-rf="Metric"
                    optionText="Metric"
                    selected={unitSystem === 'Metric'}
                  />
                   */}
                  <SelectOption
                    data-value="SI"
                    data-rf="SI"
                    optionText="SI"
                    selected={unitSystem === 'SI'}
                  />
                </div>
              </Select>
            </SelectContainer>
          </div>

          <div className="desc-textarea-container">
            <TextArea
              labelText="Description"
              size="small"
              type="primary-black"
              valid={true}
              disabled={isLoading}
              inputLimit={140}
              value={description}
              onInputChange={(event) =>
                handleSelectionDropdownChange('description', event)
              }
            />
          </div>
        </div>
      </div>
      <div className="modal-footer" slot="footer">
        <div className="modal-action-buttons">
          <Button
            type="secondary"
            size="small"
            onClick={onClose}
            className="create-project-cancel-button"
            data-rf="create-modal-cancel-button"
            disabled={isLoading}
          >
            {t(I18.modal_selection_cancel_button)}
          </Button>
          <Button
            type="primary-black"
            size="small"
            onClick={onCreateProjectClick}
            disabled={!isFormValid}
            data-rf="create-modal-create-project-button"
            loading={isLoading}
          >
            {t(I18.modal_selection_create_project_button)}
          </Button>
        </div>
      </div>
    </Modal>
  );
};

export default SelectionModal;
