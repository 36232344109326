import { useEffect, useState, useLayoutEffect } from 'react';
import { Outlet, useLocation } from 'react-router-dom';
import Footer from './components/Footer';
import Header from './components/Header';
import './styles/layout.scss';
import { CommonuxCheckboxCustomEvent } from '@abb-motion-ux/commonux-web-components';
import PrivacyBanner from '../components/PrivacyBanner';
import { GetCurrentCountry } from '../utils/GeneralUtils';
import Cookies from 'js-cookie';
import Feedback from './components/Feedback';
import { useSession } from '../utils/Auth';

const MainLayout = () => {
  const history = useLocation();
  const isWorkspacePage = history.pathname.includes('workspace');
  const [isModalOpen, setIsModalOpen] = useState(true);
  const [analyticCheckbox, setAnalyticCheckbox] = useState(false);
  const [preferencesCheckbox, setPreferencesCheckbox] = useState(false);
  const [processData, setProcessData] = useState(false);
  const [thirdParty, setThirdParty] = useState(false);
  const currentCountryId = GetCurrentCountry().id;
  const event = new CustomEvent('localdatachanged');
  const { session } = useSession();

  const initialModalValue =
    localStorage.getItem('hide_privacy_banner') === 'true';
  const [showPrivacybanner, setShowPrivacybanner] = useState(initialModalValue); // to make it even clickable this set state is needed.

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [history.pathname]);

  useLayoutEffect(() => {
    const allanalyticsValue = localStorage.getItem('allanalytics');
    const analyticsValue = localStorage.getItem('analytics');
    const preferenceValue = localStorage.getItem('preferences');
    const processValue = localStorage.getItem('processData');
    const thirdPartyValue = localStorage.getItem('thirdParty');
    if (allanalyticsValue === 'allanalytics') {
      setAnalyticCheckbox(true);
      setPreferencesCheckbox(true);
      setProcessData(true);
      setThirdParty(true);
    }
    if (analyticsValue === 'analytics') {
      setAnalyticCheckbox(true);
    }
    if (preferenceValue === 'preferences') {
      setPreferencesCheckbox(true);
    }
    if (processValue === 'processData') {
      setProcessData(true);
    }
    if (thirdPartyValue === 'thirdParty') {
      setThirdParty(true);
    }
  }, []);

  const setPrivacyBannerValue = () => {
    localStorage.setItem('hide_privacy_banner', 'true');
    setIsModalOpen(false);
  };

  //to accept all the cookies.
  const setAllTheCheckBox = () => {
    setAnalyticCheckbox(true);
    setPreferencesCheckbox(true);
    setProcessData(true);
    setThirdParty(true);
    setPrivacyBannerValue();
    localStorage.setItem('allanalytics', 'allanalytics');
    Cookies.set('allanalytics', 'allanalytics', {
      expires: 1,
      path: '/',
      domain: '.goselect.motion.abb.com',
    });
    document.dispatchEvent(event);
  };

  //refuse all the cookies.

  const refuseAllClick = () => {
    setAnalyticCheckbox(false);
    setPreferencesCheckbox(false);
    setProcessData(false);
    setThirdParty(false);
    setPrivacyBannerValue();
    localStorage.setItem('allanalytics', '');
    Cookies.set('allanalytics', '', {
      expires: 1,
      path: '/',
      domain: '.goselect.motion.abb.com',
    });
    localStorage.removeItem('analytics');
    Cookies.remove('analytics');
    localStorage.removeItem('preferences');
    localStorage.removeItem('processData');
    localStorage.removeItem('thirdParty');
    document.dispatchEvent(event);
  };

  const acceptSelectedClick = () => {
    if (analyticCheckbox && preferencesCheckbox && currentCountryId !== 'CN') {
      setAllTheCheckBox();
    }
    if (
      analyticCheckbox &&
      preferencesCheckbox &&
      currentCountryId === 'CN' &&
      processData &&
      thirdParty
    ) {
      setAllTheCheckBox();
    }
    if (analyticCheckbox) {
      localStorage.setItem('analytics', 'analytics');
      Cookies.set('analytics', 'analytics', {
        expires: 1,
        path: '/',
        domain: '.goselect.motion.abb.com',
      });
    }
    if (preferencesCheckbox) {
      localStorage.setItem('preferences', 'preferences');
    }
    if (processData) {
      localStorage.setItem('processData', 'processData');
    }
    if (thirdParty) {
      localStorage.setItem('thirdParty', 'thirdParty');
    }
    localStorage.setItem('allanalytics', '');
    Cookies.set('allanalytics', '', {
      expires: 1,
      path: '/',
      domain: '.goselect.motion.abb.com',
    });

    setPrivacyBannerValue();
    document.dispatchEvent(event);
  };

  const handleAnalyticcheckvalue = (
    event: CommonuxCheckboxCustomEvent<any>,
  ) => {
    if (event.target.id === 'analytics') {
      setAnalyticCheckbox(event.target.checked);
      if (!event.target.checked) {
        localStorage.removeItem('analytics');
        Cookies.remove('analytics');
      }
    }
    if (event.target.id === 'preferences') {
      setPreferencesCheckbox(event.target.checked);
      if (!event.target.checked) localStorage.removeItem('preferences');
    }
    if (event.target.id === 'process-data') {
      setProcessData(event.target.checked);
      if (!event.target.checked) localStorage.removeItem('processData');
    }
    if (event.target.id === 'third-party') {
      setThirdParty(event.target.checked);
      if (!event.target.checked) localStorage.removeItem('thirdParty');
    }
  };

  const onPrivacyBannerClick = () => {
    setShowPrivacybanner(false);
    setIsModalOpen(true);
  };

  return (
    <div className="layout">
      {session?.user && <Feedback />}
      <div id="content">
        <Header onCookieBannerClick={() => onPrivacyBannerClick()} />
        <Outlet />
      </div>
      {!isWorkspacePage && (
        <Footer onCookieBannerClick={() => onPrivacyBannerClick()} />
      )}
      {!showPrivacybanner && (
        <PrivacyBanner
          modalOpen={isModalOpen}
          onClose={() => setIsModalOpen(false)}
          handleAnalyticcheckvalue={handleAnalyticcheckvalue}
          analytics={analyticCheckbox}
          preferences={preferencesCheckbox}
          setAllTheCheckBox={setAllTheCheckBox}
          refuseAllClick={refuseAllClick}
          acceptSelectedClick={acceptSelectedClick}
          processData={processData}
          thirdParty={thirdParty}
        />
      )}
    </div>
  );
};

export default MainLayout;
