import React from 'react';
import Modal from '../../../components/Modal';
import Button from '../../../components/Form/Button';
import SelectionModal from '../Components/SelectionModal';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { createProject } from '../store/actions/homeActions';
import { I18 } from '../../../languages/I18';
import { useTranslate } from '../../../i18translate/Hooks';
import {
  CurrentProjectDetails,
  GeneralProjectDetails,
  ProjectType,
} from '../models/homepageModel';
import { login, useSession } from '../../../utils/Auth';
import {
  setAmbientConditionValues,
  setCurrentApplication,
  setFetchedTransformerResult,
  setTransformerInputData,
} from '../../Workspace/store/WorkspaceReducer';
import {
  initialApplicationData,
  initialResultData,
  transformerDefaultInputValues,
} from '../../Workspace/Dimensioning/models/TransformerDefaultValues';
import { RootState } from '../../../store/rootReducer';

interface LoginModalProps {
  modalOpen?: boolean;
  onClose?: () => void;
  projectData: CurrentProjectDetails[] | undefined;
}

const LoginModal: React.FunctionComponent<LoginModalProps> = ({
  modalOpen,
  onClose,
  projectData,
}) => {
  const { t } = useTranslate();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { session } = useSession();
  const [newProjectFormModalOpen, setNewProjectFormModalOpen] =
    React.useState(false);
  const projectTypeSelected = useSelector(
    (state: RootState) => state.home.projectTypeSelected,
  );

  const onNewProjectFormModalClose = () => {
    setNewProjectFormModalOpen(false);
  };

  const isLoggedIn = session?.user;

  const onSkipClick = () => {
    onClose?.();
    setNewProjectFormModalOpen(true);
  };

  const onCreateProject = (newProjectInfo: GeneralProjectDetails) => {
    dispatch(createProject(newProjectInfo));
    sessionStorage.setItem('id', newProjectInfo.projectId);
    sessionStorage.setItem('name', newProjectInfo.projectName);
    if (projectTypeSelected === ProjectType.DriveMotor.toString()) {
      navigate('/workspace');
      dispatch(setCurrentApplication(-1));
      dispatch(
        setTransformerInputData({
          transformerInput: transformerDefaultInputValues,
          selectionInputs: [initialApplicationData],
        }),
      );
      dispatch(
        setAmbientConditionValues({
          altitude: '1000',
          driveTemparature: '40',
          motorTemparature: '40',
          transformerTemparature: '40',
        }),
      );
      dispatch(
        setFetchedTransformerResult({
          transformer: {
            transformerResult: [
              { typeDesignation: '-', selectionData: initialResultData },
            ],
          },
        }),
      );
    }
  };

  return (
    <>
      {!isLoggedIn ? (
        <>
          <Modal
            open={modalOpen}
            onModalClose={onClose}
            modalTitle={t(I18.login_modal_title)}
            showCloseButton={true}
            type="default"
            showHederSeparator="true"
            backdropClick={false}
            className="login-modal"
            data-testid="login-modal"
          >
            <div
              id="login-modal-content"
              data-rf="login-modal-content"
              className="modal-content modal-container"
              slot="content"
            >
              <div data-rf="login-modal-description">
                {t(I18.login_modal_description)}
              </div>
            </div>
            <div className="modal-button-container" slot="footer">
              <div className="open-button-container">
                <Button
                  type="primary-black"
                  size="small"
                  disabled={false}
                  onBtnClick={login}
                  className="open-button"
                  data-rf="login-modal-login-button"
                >
                  {t(I18.modal_login_button)}
                </Button>
              </div>
              <div className="cancel-button-container">
                <Button
                  type="discreet-black"
                  size="small"
                  onClick={onSkipClick}
                  className="cancel-button"
                  data-rf="login-modal-skip-button"
                >
                  {t(I18.modal_login_skip_button)}
                </Button>
              </div>
            </div>
          </Modal>

          <SelectionModal
            modalOpen={isLoggedIn ? modalOpen : newProjectFormModalOpen}
            onClose={isLoggedIn ? onClose : onNewProjectFormModalClose}
            onCreateProject={onCreateProject}
            projectData={projectData}
          />
        </>
      ) : (
        <SelectionModal
          modalOpen={isLoggedIn ? modalOpen : newProjectFormModalOpen}
          onClose={isLoggedIn ? onClose : onNewProjectFormModalClose}
          onCreateProject={onCreateProject}
          projectData={projectData}
        />
      )}
    </>
  );
};

export default LoginModal;
